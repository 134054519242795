import React from 'react'

import { Tabs } from '../../../index'

import OrderedProductList from './OrderedProductList'
import CustomerNewsSnippet from './CustomerNewsSnippet'

export default function LoggedInTabs({ reorder_btn, account_btn, tabIndex }) {
  const items = [
    {
      id: 'reorder_product',
      label: reorder_btn.tab_text,
      component: OrderedProductList,
    },
    {
      id: 'customer_account_homepage',
      label: account_btn.tab_text,
      component: CustomerNewsSnippet,
    },
  ]

  return <Tabs className="logged-in-tabs" items={items} initialTab={tabIndex} />
}
