import React, { useEffect, useState } from 'react'
import { useTranslation, useCustomer } from '../../../utils'

export default function Greeting(props) {
  const { greetingMessage } = props
  const [greeting, setGreeting] = useState(greetingMessage)
  const { t } = useTranslation()
  const { fullName } = useCustomer()

  useEffect(() => {
    if (!greeting) {
      const date = new Date()
      const hour = date.getHours()
      let greet = ''
      if (hour >= 5 && hour < 11) greet = 'GOOD_MORNING'
      else if (hour >= 11 && hour < 18) greet = 'GOOD_DAY'
      else greet = 'GOOD_EVENING'

      greet = t(greet)
      setGreeting(greet)
    }
  }, [])

  return (
    <>
      <h1 className="d-block text-uppercase text-center greeting__heading">
        {greeting}
      </h1>
      {fullName && (
        <span className="d-block text-center text-uppercase mb-2 greeting__subheading">
          {fullName}
        </span>
      )}
    </>
  )
}
