import React, { Component } from 'react'

import {
  ProductPlacement,
  SnippetContent,
  LoadingSpinner,
  AlertBox,
} from '../../../index'
import StaticLink from '../../Link/StaticLink'
import {
  TranslationComponent,
  fetchReorderProductList,
} from '../../../../utils'
import { withBackendApi, withTranslation } from '../../../../utils/providers'

class OrderedProductList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      products: [],
      isLoading: false,
      error: null,
    }
  }

  componentDidMount() {
    this.fetchProductList()
  }

  fetchProductList = async () => {
    const { backendApi, language } = this.props
    this.setState({
      isLoading: true,
    })
    const { products = [], error } = await fetchReorderProductList(
      backendApi,
      language
    )
    const productsList = products?.map((product) => product.fields) || []
    this.setState({
      products: productsList,
      isLoading: false,
      error: error,
    })
  }

  render() {
    const { products, isLoading, error } = this.state

    let reorderTabContent = null
    if (isLoading) {
      return <LoadingSpinner />
    } else if (error) {
      return <AlertBox className="mx-3 mb-0">{error}</AlertBox>
    } else if (!products?.length) {
      reorderTabContent = <SnippetContent snippetId="empty_reorder_list" />
    } else {
      const productsCount = products.length
      reorderTabContent = (
        <>
          <ProductPlacement products={products.slice(0, 4)} />
          {productsCount > 4 && (
            <StaticLink
              href="/reorder/category/view"
              activeClassName="text-primary"
              className="more-product"
            >
              <TranslationComponent text="SHOW_MORE" />
              <i className="cf-next ml-2" />
            </StaticLink>
          )}
        </>
      )
    }
    return <div className="ordered-product-list">{reorderTabContent}</div>
  }
}

export default withBackendApi(withTranslation(OrderedProductList))
