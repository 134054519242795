import React from 'react'
import { useRouter } from 'next/router'
import classNames from 'classnames'

import { default as NextLink } from 'next/dist/client/link'

function StaticLink(props) {
  const {
    children,
    href,
    activeClassName,
    className,
    forceActive = false,
    ...rest
  } = props
  const router = useRouter()
  const buttonClassName = classNames(className, {
    [activeClassName]: router?.asPath === href || forceActive,
  })
  const link = (
    <a className={buttonClassName} {...rest}>
      {children}
    </a>
  )
  if (!href) {
    return link
  }
  const [path, params] = href.split('?')
  const internalHref =
    `/frontend/static?seoUrl=` + path + (params ? '&' + params : '')
  const externalHref = href

  return (
    <>
      <NextLink href={internalHref} as={externalHref}>
        {link}
      </NextLink>
    </>
  )
}

export default StaticLink
