export default (t) => [
  {
    name: t('PALI_VARIANT_DEFAULT'),
    description: t('PALI_WELCOME_BLOCK_DESCRIPTION'),
    props: {
      greeting_msg: t('PALI_SAMPLE_HEADING_LONG'),
      reorder_btn: {
        btn_text: t('PALI_SAMPLE_BUTTON'),
        tab_text: t('PALI_SAMPLE_HEADING_SHORT'),
        login_popup_heading: t('PALI_SAMPLE_HEADING_LONG'),
      },
      account_btn: {
        btn_text: t('PALI_SAMPLE_BUTTON'),
        tab_text: t('PALI_SAMPLE_HEADING_SHORT'),
        login_popup_heading: t('PALI_SAMPLE_HEADING_LONG'),
      },
    },
  },
]
