import { fetchMakairaProducts } from '../../index'

export default async function fetchReorderProductList(backendApi, language) {
  let error = null
  const products = []

  // Fetch list of product IDs from the backend
  const { productIds, status, message } = await backendApi
    .getReorderProductList()
    .catch((err) => {
      error = err
    })

  // Fetch product data from Makaira
  if (status !== 'OK') {
    error = message || status
  } else if (productIds?.length > 0) {
    await fetchMakairaProducts(productIds, language)
      .then((results) => {
        results.map((item) => {
          if (item.active) {
            // <List> expects the product structure of the fetchAutosuggestResult(),
            // so we're faking it here.
            products.push({
              id: item.id,
              fields: item,
            })
          }
        })
      })
      .catch((err) => {
        error = err
      })
  }

  return {
    error,
    products,
  }
}
