import React from 'react'

import { useSnippet } from '../../../../utils'
import { ContentElements, LoadingSpinner } from '../../../index'

export default function CustomerNewsSnippet() {
  const [newsBlock, accountOverview] = useSnippet([
    'customer_news_block',
    'customer_account_overview',
  ])
  // Prefer 'customer_news_block', fall back to 'customer_account_overview'
  const elements = newsBlock?.length > 0 ? newsBlock : accountOverview
  if (!elements?.length) {
    return <LoadingSpinner />
  }
  return <ContentElements elements={elements} />
}
