import React, { useState } from 'react'

import { useCustomer } from '../../../utils'

import Greeting from './Greeting'
import LoginButtons from './LoginButtons'
import LoggedInTabs from './LoggedInTabs'
import classNames from 'classnames'

export default function WelcomeBlock(props) {
  const { isLoggedIn } = useCustomer()
  const [tabIndex, setTabIndex] = useState(0)
  const { greeting_msg, isFullWidth = false } = props
  const containerClasses = classNames('my-3', {
    container: !isFullWidth,
  })
  return (
    <div className={containerClasses}>
      <Greeting greetingMessage={greeting_msg} />
      {!isLoggedIn && <LoginButtons {...props} setTabIndex={setTabIndex} />}
      {isLoggedIn && <LoggedInTabs {...props} tabIndex={tabIndex} />}
    </div>
  )
}

export { default as welcomeBlockVariants } from './variants.js'
