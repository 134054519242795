import React from 'react'
import { dispatchLoginPopupShowEvent } from '../LoginPopup'
import { Button } from '../../index'
import classNames from 'classnames'

export default function LoginButtons({
  reorder_btn,
  account_btn,
  setTabIndex,
  isFullWidth,
}) {
  const containerClasses = classNames('row mt-3', {
    'mx-0': isFullWidth,
  })

  return (
    <div className={containerClasses}>
      <div className="col-12 col-md-3 offset-md-3 mb-2 mb-md-0">
        <Button
          onClick={() => {
            setTabIndex(0)
            dispatchLoginPopupShowEvent({
              headline: reorder_btn.login_popup_heading,
            })
          }}
          variant="primary"
          className="w-100"
        >
          {reorder_btn.btn_text}
        </Button>
      </div>
      <div className="col-12 col-md-3">
        <Button
          onClick={() => {
            setTabIndex(1)
            dispatchLoginPopupShowEvent({
              headline: account_btn.login_popup_heading,
            })
          }}
          variant="primary"
          className="w-100"
        >
          {account_btn.btn_text}
        </Button>
      </div>
    </div>
  )
}
